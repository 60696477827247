import React from 'react';
import autoBind from 'react-autobind';
import { Layout, PageHeader, Table, Card, Row, Col, Form, Select, Button } from 'antd';
//
import CustomComponent from '../../components/CustomComponent';
import CommonLoadingView from '../commonComponents/CommonLoadingView';
//
import config from '../../config/config';
import Utils from '../../components/Utils';
import Globals from '../../config/Globals';
// import Globals from '../../config/Globals';
//
export default class AdminStatusReportView extends CustomComponent {
  constructor(props) {
    super(props);
    autoBind(this);
    const queryParams = new URLSearchParams(window.location.search);
    const selectedFilter = queryParams.get('filter');
    this.state = {
      isLoading: false, isExporting: false,
      selectedFilter: selectedFilter || null,
      sortedInfo: {}, data: [], orgs: null,
    };
  }

  componentDidMount() {
    if (this.state.selectedFilter) {
      this.handleChangeFilter(this.state.selectedFilter);
    }
  }

  // Actions
  handleFilterChange(pagination, filters, sorter) { this.setState({ sortedInfo: sorter }); }

  handleChangeFilter(selectedFilter) {
    this.setState({ data: [], selectedFilter }, this._fetch);
  }

  async handleExport() {
    this.setState({ isExporting: true });

    const { data } = this.state;

    const rows = data.reduce((acc, curr) => {
      return [
        ...acc,
        [
          `${curr.userObj.firstName} ${curr.userObj.lastName}`,
          curr.userObj.email,
          curr.userObj.phoneNumber,
          !curr.userObj.orgID ? 'Unknown Employer' : (!this.state.orgs ? 'Unavailble' : this.state.orgs.find((o) => o.id == curr.userObj.orgID)?.name),
          curr.userObj.companyLocation,
          curr.userObj.companyContact,
          curr.userObj.companyContactPhone,
          Utils.getDateOnUIFormatByTimestamp(curr.userObj.agreedOnTerms),
          curr.certification?.certificationShortDescription,
          curr.certification?.certNumber,
          curr.certification ? Utils.getDateOnUIFormatByTimestamp(curr.certification?.createdOn) : '-',
          curr.certification?.state,
          curr.state == Globals.Exam_State.PENDING_RES_APPROVAL ? Utils.getDateOnUIFormatByTimestamp(data.examResultDate) : '',
          curr.type,
          curr.paymentDate ? Utils.getDateOnUIFormatByTimestamp(curr.paymentDate) : '-',
          curr.state,
          curr.examDate,
          curr.state == Globals.Exam_State.PENDING ? '' : curr.state,
          //curr.state == Globals.Exam_State.PASS && curr.type == 'Written' ? Utils.getDateOnUIFormatByTimestamp(curr.expiryDate) : ''
          curr.state == (Globals.Exam_State.PASS && curr.type == 'Written') || (curr.responseType && curr.responseType == 'CP') ? Utils.getDateOnUIFormatByTimestamp(curr.expiryDate) : ''
        ]
      ];
    }, []);

    await Utils.generateXLSX({
      name: 'Status Report',
      columns: [
        'Name', 'Email', 'Phone', 'Company Name', 'Location', 'Dispatcher', 'Disp. Phone',
        'Agreed on Terms', 'Certification', 'Cert.Number', 'Started', 'State',
        'Completed', 'Exam Type', 'Payment Date', 'State', 'Schedule', 'Result', 'Expiry Date',
      ],
      rows,
    });

    this.setState({ isExporting: false });
  }

  handleRow(record) {
    return {
      onClick: () => {
        this.props.app.pushPage(config.ApplicationRoutes.userCertification, {
          from: 'statusReports',
          filter: this.state.selectedFilter,
        }, record.certProcID, record.userObj.id);
      },
    };
  }

  render() {
    const { sortedInfo } = this.state;
    const columns = [
      {
        title: 'Name', dataIndex: 'userObj', key: 'name',
        render: (user) => `${user.firstName} ${user.lastName}`,
        sorter: (a, b) => (a.userObj.firstName + ' ' + a.userObj.lastName).localeCompare((b.userObj.firstName + ' ' + b.userObj.lastName)),
        sortOrder: sortedInfo.columnKey === 'name' && sortedInfo.order,
      },
      { title: 'Email', dataIndex: 'userObj.email', key: 'email' },
      { title: 'Phone', dataIndex: 'userObj.phoneNumber', key: 'phoneNumber' },
      { title: 'Company Name', dataIndex: 'userObj.orgID', key: 'orgID',
        render: (orgID) => !orgID ? 'Unknown Employer' : (!this.state.orgs ? 'Loading...' : this.state.orgs.find((o) => o.id == orgID)?.name) },
      { title: 'Location', dataIndex: 'userObj.companyLocation', key: 'companyLocation' },
      { title: 'Dispatcher', dataIndex: 'userObj.companyContact', key: 'companyContact' },
      { title: 'Disp. Phone', dataIndex: 'userObj.companyContactPhone', key: 'companyContactPhone' },
      {
        title: 'Agreed on Terms', dataIndex: 'userObj.agreedOnTerms', key: 'agreedOnTerms',
        render: (date) => Utils.getDateOnUIFormatByTimestamp(date),
      },
      { title: 'Certification', dataIndex: 'certification.certificationShortDescription', key: 'certification.certificationShortDescription' },
      { title: 'Cert. Number', dataIndex: 'certification.certNumber', key: 'certification.certNumber' },
      {
        title: 'Started', dataIndex: 'certification.createdOn', key: 'certification.createdOn',
        render: (date) => Utils.getDateOnUIFormatByTimestamp(date),
      },
      { title: 'State', dataIndex: 'certification.state', key: 'certification.state' },
      {
        title: 'Completed', key: 'certification.completionDate',
        render: (data) => data.state == Globals.Exam_State.PENDING_RES_APPROVAL ? Utils.getDateOnUIFormatByTimestamp(data.examResultDate) : '',
      },
      { title: 'Exam type', dataIndex: 'type', key: 'type' },
      {
        // should be last position from financial transactions
        title: 'Payment Date', dataIndex: 'paymentDate', key: 'paymentDate',
        render: (date) => Utils.getDateOnUIFormatByTimestamp(date),
      },
      { title: 'State', dataIndex: 'state', key: 'state' },
      {
        // hide dates when is -1
        title: 'Schedule', dataIndex: 'examDate', key: 'examDate',
        render: (date) => Utils.getDateOnUIFormatByTimestamp(date),
      },
      {
        title: 'Result', dataIndex: 'state', key: 'result',
        render: (state) => state == Globals.Exam_State.PENDING ? '' : state,
      },
      {
        title: 'Expiry Date', key: 'expiryDate',
        render: (data) => (
          data.state == (Globals.Exam_State.PASS && data.type == 'Written') || (data.responseType && data.responseType == 'CP') ? Utils.getDateOnUIFormatByTimestamp(data.expiryDate) : ''
        )
      },
    ];

    const props = {
      rowKey: 'id', loading: this.state.isLoading, onChange: this.handleFilterChange,
      scroll: { x: 3500 },
      pagination: { pageSize: 50, hideOnSinglePage: true},
    };

    return (
      <Layout.Content className="dashboard userDashboard">
        <CommonLoadingView isLoading={this.state.isLoading} />
          <PageHeader className="pageHeader" title="Status Report"
                      onBack={() => this.props.app.pushPage(config.ApplicationRoutes.homepage)}/>
          <Layout.Content>
            <Card>
              <Form layout="vertical">
                <Row>
                  <Col span={24}>
                    <Form.Item label="Filter by">
                      <Select
                        defaultValue={this.state.selectedFilter}
                        placeholder="Select a filter to list members..."
                        onChange={this.handleChangeFilter}
                        style={{ width: 400 }}
                      >
                        <Select.Option value="allExams">All</Select.Option>
                        <Select.Option value="paidIncomplete">Users with paid and incomplete written exam</Select.Option>
                        <Select.Option value="pendingPracticalScheduling">Users pending practical assessment scheduling</Select.Option>
                        <Select.Option value="pendingPractialApproval">Users pending approval of practical assessment results</Select.Option>
                        <Select.Option value="pendingPaymentPracticalExams">Users pending practical assessment payment</Select.Option>
                        <Select.Option value="completedCertifications">Users whose certification is in completed status</Select.Option>
                      </Select>

                      <Button
                        icon="download"
                        type="primary"
                        style={{ marginLeft: 8 }}
                        disabled={this.state.data.length < 1}
                        onClick={this.handleExport}
                        loading={this.state.isExporting}
                      >
                        {this.state.isExporting ? 'Generating file...' : 'Export to Excel'}
                      </Button>
                    </Form.Item>
                  </Col>
                </Row>
              </Form>
            </Card>
            <Table className="adminSearchUsersTable" onRow={this.handleRow} style={{ marginTop: 16 }}
              columns={columns} dataSource={this.state.data} {...props}/>
          </Layout.Content>
      </Layout.Content>
    );
  }

  // API Calls
  async _fetch() {
    this.setState({ isLoading: true });

    const { selectedFilter } = this.state;

    const resp = await this.props.app.api.statusReport[selectedFilter]();
    if (resp.statusCode == 200 && resp.body && resp.body.exams) {
      const data = resp.body.exams.sort((a, b) => {
        const textA = `${a.userObj.firstName} ${a.userObj.lastName}`.toLowerCase();
        const textB = `${b.userObj.firstName} ${b.userObj.lastName}`.toLowerCase();
        return (textA < textB) ? -1 : (textA > textB) ? 1 : 0;
      });
      //get last item createdOn on financialTransactions and add it to paymentDate
      for (const exam of data) {
        if (exam.financialTransactions) {
          const lastTransaction = exam.financialTransactions[exam.financialTransactions.length - 1];
          if (lastTransaction) exam.paymentDate = lastTransaction.createdOn;
        }
      }
      this.setState({ data, orgs: null }, this._fetchCompanies);
    } else {
      this.props.app.alertController.showAPIErrorAlert(null, resp);
    }

    this.setState({ isLoading: false });
  }
  async _fetchCompanies() {
    const orgIDs = this.state.data.map(o => o.userObj.orgID).filter(o => !!o);
    if (!orgIDs || orgIDs.length == 0) return;
    const orgsResp = await this.props.app.organization.organization.searchOrganizationsByIDs({ orgIDs });
    if (orgsResp.statusCode == 200 && orgsResp.body?.orgs) this.setState({ orgs: orgsResp.body?.orgs });
    else this.props.app.alertController.showAPIErrorAlert(null, orgsResp);
  }
}
