import Globals from '../../../config/Globals';
//
export default class APIStatusReport {
  constructor(API) {
    this.api = API;
  }

  async allExams() {
    const req = await this.api.newBaseRequest('POST');
    req.path = Globals.APIPath_Report + '/allExams';
    return await req.exec();
  }

  async paidIncomplete() {
    const req = await this.api.newBaseRequest('POST');
    req.path = Globals.APIPath_Report + '/written/incomplete';
    return await req.exec();
  }

  async pendingPracticalScheduling() {
    const req = await this.api.newBaseRequest('POST');
    req.path = Globals.APIPath_Report + '/practical/incomplete';
    return await req.exec();
  }

  async pendingPractialApproval() {
    const req = await this.api.newBaseRequest('POST');
    req.path = Globals.APIPath_Report + '/practical/pendingApproval';
    return await req.exec();
  }

  async pendingPaymentPracticalExams() {
    const req = await this.api.newBaseRequest('POST');
    req.path = Globals.APIPath_Report + '/practical/pendingPayment';
    return await req.exec();
  }

  async completedCertifications() {
    const req = await this.api.newBaseRequest('POST');
    req.path = Globals.APIPath_Report + '/certification/complete';
    return await req.exec();
  }
}
