module.exports = {
  "IDMClientTokenKey": "363745ee-1ccd-5f1e-9507-7f8d98450c59",
  "IDMClientTokenSecret": "2d166343-3c17-5d70-8994-4648306ad24f",
  "IDMClientOptions": {
    "autoEnrollRole": [
      "1d3472dc-cdc_CCPO",
      "9d6d3cf4-04a_BC___Students_Portal"
    ],
    "externalAuth": true,
    "externalAuthDomain": "portal.bccsa-services.ca",
    "externalAuthPath": "/login",
    "externalValidatePath": "/validate",
    "externalRegistrationPath": "/register",
    "externalProfilePath": "/profile",
    "apiEndpoint": "https://api-auth.bccsa-services.ca",
    "cookiesEndpoint": "https://portal.bccsa-services.ca",
    "roles": {
      "USER": "1d3472dc-cdc_CCPO",
      "ADMIN": "3a00bb68-64e_CCPO",
      "ADVISOR": "b27bb524-1b5_CCPO",
      "IDM_USER": "59a72bbd-9a43-427e-9c92-afd74ab19431",
      "SHARED_MODULES": "9d6d3cf4-04a_BC___Students_Portal"
    },
    "partitions": {
      "CCPO": "ca.bccsa.ccpo",
      "PERSONAL": "ca.bccsa.personal",
      "PROFESSIONAL": "ca.bccsa.professional"
    }
  },
  "CSPFile": "prod.js",
  "ApplicationName": "CCPO",
  "ApplicationAPIEndpoint": "https://api-ccpo.bccsa-services.ca",
  "ApplicationTenantID": "01",
  "SMOrganizationEndpoint": "https://api-org.bccsa-services.ca",
  "SMOrganizationTenantID": "CCPOBC-01P",
  "LogRocketOptions": {
    "applicationId": "ue6tsn/ccpo-ob2m8"
  },
  "ApplicationRoutes": {
    "login": "/",
    "homepage": "/",
    "profile": "/profile",
    "workHours": "/workhours",
    "userWorkHours": "/workhours/:id",
    "certification": "/cert/:id",
    "userCertification": "/cert/:id/:id2",
    "reports": "/reports",
    "settings": "/settings",
    "userDashboard": "/user/:id",
    "userProfile": "/user/:id/profile",
    "userSearch": "/users",
    "pendingWaiveRequests": "/waives/pending",
    "adminCertification": "/certifications",
    "usersImport": "/importUsers",
    "statusReport": "/statusReport",
    "adminBillingView": "/billing/:id",
    "simpleBillingView": "/billing"
  },
  "Stage": "prod"
}